
export async function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=" + process.env.REACT_APP_COOKIE_DOMAIN;
}

export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        // eslint-disable-next-line eqeqeq
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        // eslint-disable-next-line eqeqeq
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export async function eraseCookie(name) {
    document.cookie = name + '=; domain=' + process.env.REACT_APP_COOKIE_DOMAIN + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}


export async function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}