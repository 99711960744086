import React from "react";
import ReactDOM from "react-dom";
import './translations';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

const app = (
  <GoogleOAuthProvider clientId="352569723461-o6ghce50npuo1etulv1424tlk2jvovvl.apps.googleusercontent.com">
  <BrowserRouter>
   <App />
  </BrowserRouter>
  </GoogleOAuthProvider>

);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
