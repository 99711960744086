
import { isMobile, isDesktop } from 'react-device-detect';

const initialState = {
    data: {
      data: [],
      loading: false,
      page: 0,
      pageSize: 8,
      totalCount: 0,
      total_pages: 0
    },
    error: null,
    selectedRows: [],
    payload: {}
  };
  
  export const queryPageInfinityRequest = (namespace) => (state = initialState, action) => {
    switch (action.type) {
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_REQUEST`: {
        return { ...state, data: { ...state.data, loading: true }, error: null };
      }
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_SUCCESS`: {
        return { ...state, data: { ...state.data, data: state?.data?.data?.concat(action?.data?.results), page: action?.data?.page_number, pageSize: state.data.pageSize, totalCount: action?.data?.row_count, total_pages: action.data?.total_pages ,loading: false } };
      }
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_FAILURE`: {
        return { ...state, data: { ...state.data, data: [], page: 0, pageSize: state.data.pageSize, totalCount: 0, loading: false }, error: action.error };
      }
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_SET_PAGE`: {
        return { ...state, data: { ...state.data, page: action?.data?.page } }
      }
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_RESET_PAGE`: {
        return { ...state, data: { ...state.data, page: 0 } }
      }
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_SET_PAGE_SIZE`: {
        return { ...state, data: { ...state.data, page: 0, pageSize: action?.data?.pageSize } }
      }
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_RESET_PAGE_SIZE`: {
        return { ...state, data: { ...state.data, pageSize: 10 } }
      }
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_SET_PAGE_AND_PAGE_SIZE`: {
        return { ...state, data: { ...state.data, page: action?.data?.page, pageSize: action?.data?.pageSize } }
      }
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_SET_PAYLOAD`: {
        return { ...state, data: { ...state.data, data: [], page: 0, pageSize: state.data.pageSize }, payload: action.data };
      }
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_RESET_PAYLOAD`: {
        return initialState;
      }
      case `${namespace}/QUERY_PAGE_INFINITY_REQUEST_RESET`: {
        return initialState
      }
      default:
        return state;
    }
  };
  