const initialState = {
  data: [],
  loading: false,
  success: false,
  error: false,
  out_of_stock: []
};

export const patchRequest = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/PATCH_REQUEST_REQUEST`: {
      return { ...initialState, loading: true, success: false };
    }
    case `${namespace}/PATCH_REQUEST_SUCCESS`: {
      return { ...state, data: action.data, loading: false, success: true, out_of_stock: action?.data?.out_of_stock  };
    }
    case `${namespace}/PATCH_REQUEST_FAILURE`: {
      return { ...state, data: action.error, loading: false, success: false, error: true };
    }
    default:
      return state;
  }
};
