import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";

import { setCookie, getCookie, deleteAllCookies } from "../../utils/cookie";

import CookiePolicyTr from '../../assets/policies/KVKK.pdf'

class cookie extends PureComponent {

  state = {
    cookie: getCookie('cookie-policy')
  };

  acceptCookie = async () => {
    const { i18n } = this.props;

    await deleteAllCookies();
    await setCookie('cookie-policy', 'true', 365);
    await setCookie('i18nextLng', i18n.language, 365);
    this.setState({ cookie: getCookie('cookie-policy') });
  }


  rejectCookie = async () => {
    await deleteAllCookies();
    await setCookie('cookie-policy', 'false', 365);
    this.setState({ cookie: getCookie('cookie-policy') });
  }


  render() {
    const {  i18n } = this.props;

    return (
      <React.Fragment>
        <div className={`"alert text-center cookie  ${!this.state.cookie ? "show" : ""} `} role="alert">

          <div className="d-flex flex-column align-items-center mt-2">
            {i18n.language === 'tr'||i18n.language === 'tr-TR' ?
              <p className="font-size-12"><i className="fas fa-cookie-bite font-size-20 pr-1 text-pink" />Site içeriğini, önerilerinizi kişiselleştirmek ve site trafiğini analiz etmek için üçüncü taraf çerezleri kullanıyoruz. <b><a className="text-pink-light text-underline" href={CookiePolicyTr} target="_blank" rel="noreferrer">Çerez Politikamıza</a></b> bakabilirsiniz.</p>
              :
              <p className="font-size-12"> <i className="fas fa-cookie-bite font-size-20 pr-1 text-pink" />We use third-party cookies to personalize site content, your recommendations and analyze site traffic. To learn more about the cookies we use, see our <b><a href={CookiePolicyTr} target="_blank" className="text-pink-light text-underline" rel="noreferrer">Cookie Policy</a></b>.</p>
            }
            <div className="d-flex align-items-center">
            <div className="mb-2 px-4">
              <button type="button" onClick={() => this.acceptCookie()} >
                {i18n.language === 'tr'||i18n.language === 'tr-TR' ?
                  <span className="font-size-14 font-weight-500">Kabul Et</span>
                  :
                  <span className="font-size-14 font-weight-500">I agree</span>
                }
              </button>
            </div>
            <div className="mb-2">
              <button type="button" onClick={() => this.rejectCookie()} >
                {i18n.language === 'tr'||i18n.language === 'tr-TR' ?
                  <span className="font-size-14 font-weight-500">Reddet</span>
                  :
                  <span className="font-size-14 font-weight-500">Cancel</span>
                }
              </button>
            </div>
            </div>
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation('common')(cookie);
