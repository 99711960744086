const initialState = {
    loading: false,
    success: false,
    error: false,
  };
  
const mailRegistrationReducer =  function (state = initialState, action) {
    switch (action.type) {
      case 'MAIL_REGISTRATION_REQUEST': {
        return {...initialState, loading: true, success: false};
      }
      case 'MAIL_REGISTRATION_SUCCESS': {
        return {...state, success: true, loading: false};
      }
      case 'MAIL_REGISTRATION_FAILURE': {
        return {...state, error: true, loading: false, success: false};
      }
      default:
        return state;
    }
  }
  
  export default mailRegistrationReducer