import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { reducer as notifications } from "react-notification-system-redux";

import {patchRequest, putRequest,  loginReducer, mailRegistrationReducer, signupReducer, queryPageRequest, queryPageInfinityRequest, postRequest, getRequest, getRequestWithoutAuthorization, getByIdRequest } from "./redux/reducers/index";



const reducer = combineReducers({
  // Master
  countryList: getRequest('accounts/countries/list'),
  cityList: getRequest('global/cities/all'),
  cityHasSystemList: getRequest('global/cities'),
  townList: getByIdRequest('accounts/towns/list/id'),
  districtsList: getByIdRequest('global/districts/all'),
  travelCategoryList: getRequest('global/travel_category'),

  categoryList: getRequestWithoutAuthorization('global/categories'),
  
  // Notification
  notifications,

  // Auth 
  login: loginReducer,
  mailRegistration: mailRegistrationReducer,
  signup: signupReducer,
  resetPassword: postRequest('auth/reset-password'),
  forgotPassword: postRequest('auth/request-reset-password'),
  //Place
  placeListInfinityQueryPage: queryPageInfinityRequest('places'),
  placeDetailInfo: getByIdRequest('places'),
  placeCommentList: getByIdRequest('places/comments'),
  placeWorkingHour: getByIdRequest('places/opening_hours'), 
  placeTables: getByIdRequest('reservation/tables'),
  placeReservationUnavailableDates: getByIdRequest('reservation/check/unavailable/dates'),
  placeReservation: postRequest('reservation/create'),
  placeSearchList: getRequest('places'),
  placeRate: postRequest('places/comments/create'),
  placeCampaign: getByIdRequest('places/campaign'),
  //Dashboard
  populerActivitiesList: getRequest('activity?page_size=10&page=0'),
  populerPlaceList: getRequest('places?page_size=10&page=0'),
  //Profile
  userProfileInfo: getRequest('auth/user'),
  userProfileEdit: patchRequest('auth/user/edit'),
  
  activityQueryPageList: queryPageInfinityRequest('activity'),
  activitySearchList: getRequest('activity'),
  activityDetail: getByIdRequest('activity'),

  //User Favorite Place
  favoritePlaceList: getRequest('favorite/all'),
  placeFavoriteRemove: putRequest ('favorite/remove'),
  placeFavoriteAdd: putRequest('favorite/add'),

  //Order
  userOrder: postRequest('ticket/order'),
  userOrderAllQueryPage: queryPageRequest('ticket/user'),
  ticketDetail: getByIdRequest('ticket/user'),
  userReservationAllQueryPage: queryPageRequest('auth/user/reservations'),

  //Blog

  guideListInfinityQueryPage: queryPageInfinityRequest('guide'),
  guideDetail: getByIdRequest('guide'),

  //Contact

  contactUs: postRequest('contact/message/send')
});





const store = createStore(reducer, applyMiddleware(thunk));

export default store;
