
const initialState = {
  data: {
    data: [],
    loading: false,
    page: 0,
    pageSize: 10,
    totalCount: 0,
    intervalLoading: undefined
  },
  error: null,
  selectedRows: [],
  payload: {},

};
export const queryPageRequest = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/QUERY_PAGE_REQUEST_REQUEST`: {
      return { ...state, data: { ...state.data, loading: true, intervalLoading: action.intervalLoading }, error: null, };
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SUCCESS`: {
      return { ...state, data: { ...state.data, data: action?.data?.results, page: action?.data?.page_number - 1, pageSize: state.data.pageSize, totalCount: action?.data?.row_count, loading: false, intervalLoading: action.intervalLoading } };
    }
    case `${namespace}/QUERY_PAGE_REQUEST_FAILURE`: {
      return { ...state, data: { ...state.data, data: [], page: 0, pageSize: state.data.pageSize, totalCount: 0, loading: false, intervalLoading: false }, error: action.error };
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SET_PAGE`: {
      return { ...state, data: { ...state.data?.data, page: action?.data?.page } }
    }
    case `${namespace}/QUERY_PAGE_REQUEST_RESET_PAGE`: {
      return { ...state, data: { ...state.data?.data, page: 0 } }
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SET_PAGE_SIZE`: {
      return { ...state, data: { ...state.data?.data, page: 0, pageSize: action?.data?.pageSize } }
    }
    case `${namespace}/QUERY_PAGE_REQUEST_RESET_PAGE_SIZE`: {
      return { ...state, data: { ...state.data?.data, pageSize: 10 } }
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SET_PAGE_AND_PAGE_SIZE`: {
      return { ...state, data: { ...state.data?.data, page: action?.data?.page, pageSize: action?.data?.pageSize } }
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SET_PAYLOAD`: {
      return { ...state, data: { ...state.data?.data, page: 0, pageSize: state.data.pageSize }, payload: action.data };
    }
    case `${namespace}/QUERY_PAGE_REQUEST_RESET_PAYLOAD`: {
      return { ...state, data: { ...state.data?.data, page: 0, pageSize: state.data.pageSize }, payload: { ...initialState.payload } };
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SET_BY_TIME`: {
      return { ...state, data: { ...state.data?.data, loading: false }, error: null };

    }
    case `${namespace}/QUERY_PAGE_REQUEST_RESET`: {
      return initialState;
    }

    default:
      return state;
  }
};
