const initialState = {
    success: false,
    error: false,
  };
  
 const signupReducer = function (state = initialState, action) {
    switch (action.type) {
      case 'SIGNUP_REQUEST': {
        return {...initialState, loading: true};
      }
      case 'SIGNUP_SUCCESS': {
        return {...state, success: true, loading: false};
      }
      case 'SIGNUP_FAILURE': {
        return {...state, error: true, loading: false};
      }
      default:
        return state;
    }
  }
   export default signupReducer