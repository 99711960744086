import React, { Component, Suspense } from "react";
import { Provider } from 'react-redux';
import store from './store';

import { Router } from "react-router-dom";
import { history } from "./utils/helpers/history";
import Routers from "./router";
// Notification
import NotificationContainer from "./components/Shared/Notification";

// Util functions
import { scrollNavigation } from "./utils";
import { HelmetProvider } from 'react-helmet-async';

// Import Css
import "./Apps.scss";
import ScrollToTop from "./utils/helpers/scrollToTop";
import { getCookie } from "./utils/cookie";
import CookieAggrement from "./components/Shared/CookieAggrement";
// import { generateSitemap } from "./SitemapGenerator";

class App extends Component {
state={
  isDiscountModalOpen: false,

}
  componentDidMount() {
    window.addEventListener("scroll", scrollNavigation, true);
    if(JSON.parse(localStorage.getItem('discountAdvertising')) !== true ){
      if(history.location.pathname !== '/order/success' && history.location.pathname !== '/order/unsuccessful'){
        this.openDiscountModal();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", scrollNavigation, true);
  }

  openDiscountModal = () => {
    this.setState({ isDiscountModalOpen: true });
  };

  closeDiscountModal = () => {
    this.setState({ isDiscountModalOpen: false });
    localStorage.setItem('discountAdvertising', true)
  };

  Loader = () => {
    return (
        // <div style={{ position: 'absolute', top: '50%', bottom: '50%', right: '50%', left: '50%' }}>
        //   <div class="loader"></div>
        // </div>
        <div className="preloader-wrapper" >
        <div className="preloader">
          <div className="spinner">
            <div className="spin spinner"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const helmetContext = {};
    // const sitemap = generateSitemap();
    // console.log(sitemap);
    return (
      <React.Fragment>
        <HelmetProvider context={helmetContext}>
          <Provider store={store}>
            <Router basename="/" history={history}>
              <Suspense fallback={this.Loader()}>
                <ScrollToTop />
                <Routers />
              </Suspense>
            </Router>
            {!getCookie('cookie-policy') 
            && history.location?.pathname !== '/order/success' 
            && history.location?.pathname !== '/order/unsuccessful' &&
            <CookieAggrement />
          }
            <NotificationContainer props />
          </Provider>
        </HelmetProvider >   
      

        {/* <DiscountCodeModal
          isOpen={this.state.isDiscountModalOpen}
          onOpen={this.openDiscountModal}
          onClose={this.closeDiscountModal}
        />       */}
        </React.Fragment>
    );
  }
}

export default App;
