import React from 'react';
import { Route, Switch } from 'react-router-dom';
const WrappedRoutes = React.lazy(() => import("./WrappedRoutes"));
const AuthRoutes = React.lazy(() => import("./AuthRoutes"));

const Router = () => {
  
  return (
    <Switch>
      <Route path="/auth" component={AuthRoutes} />
      <Route path="/" component={WrappedRoutes} />
    </Switch>
  );
};

export default Router;
